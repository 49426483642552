<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        v{{ version }} - {{ $t('footer-release-notes') }} -
        {{ formattedReleaseDate }}
      </p>
    </header>
    <section class="modal-card-body">
      <div
        style="margin-left: 1em; margin-right: 1em"
        v-html="releaseNoteIntro"
      >
        <br />

        <p class="is-size-5 has-text-weight-bold mt-05">
          {{ $t('release-notes-new-features') }}
        </p>
        <ul>
          <li v-for="item in features" :key="item">
            {{ item }}
          </li>
        </ul>
        <p class="is-size-5 has-text-weight-bold mt-05">
          {{ $t('release-notes-new-improvements') }}
        </p>
        <ul>
          <li v-for="item in improvements" :key="item">
            {{ item }}
          </li>
        </ul>
        <p class="is-size-5 has-text-weight-bold mt-05">
          {{ $t('release-notes-bug-fixes') }}
        </p>
        <ul>
          <li v-for="item in bugFixes" :key="item">
            {{ item }}
          </li>
        </ul>
      </div>
      <br />
      <b-collapse :open="false">
        <a slot="trigger" @click="toggleMessage = !toggleMessage">
          {{
            toggleMessage
              ? $t('release-notes-hide-older')
              : $t('release-notes-show-older')
          }}
        </a>
        <div
          v-for="release in oldReleases"
          :key="release.version"
          class="notification"
        >
          <p
            v-if="release.date"
            class="is-size-6 has-text-grey has-text-weight-bold"
          >
            {{ $t('release-notes-release-date') }}: {{ release.date }}
          </p>
          <p class="is-size-6 has-text-grey has-text-weight-bold">
            v{{ release.version }}
          </p>
          <p class="is-size-6 has-text-weight-bold">
            {{ $t('release-notes-new-improvements') }}
          </p>
          <ul>
            <li v-for="item in release.improvements" :key="item">
              {{ item }}
            </li>
          </ul>
          <p class="is-size-6 has-text-weight-bold">
            {{ $t('release-notes-new-features') }}
          </p>
          <ul>
            <li v-for="item in release.features" :key="item">
              {{ item }}
            </li>
          </ul>
          <p class="is-size-6 has-text-weight-bold">
            {{ $t('release-notes-bug-fixes') }}
          </p>
          <ul>
            <li v-for="item in release.bugFixes" :key="item">
              {{ item }}
            </li>
          </ul>
        </div>
      </b-collapse>
    </section>

    <footer class="modal-card-foot">
      <b-button type="is-primary" @click="$parent.close()">
        {{ $t('release-notes-close-button-label') }}
      </b-button>
    </footer>
  </div>
</template>

<script>
import releaseNoteData from '../../../data/release-notes.json';
import { defineComponent } from '@vue/composition-api';
import { DateTime } from 'luxon';

export default defineComponent({
  data() {
    return {
      toggleMessage: false,
      currentRelease: {},
      oldReleases: [],
    };
  },
  computed: {
    formattedReleaseDate() {
      if (!this.releaseDate) return '';
      return DateTime.fromFormat(this.releaseDate, 'dd-MM-yyyy').toLocaleString(
        DateTime.DATE_FULL,
      );
    },
    bugFixes() {
      return this.currentRelease.bugFixes;
    },
    improvements() {
      return this.currentRelease.improvements;
    },
    features() {
      return this.currentRelease.features;
    },
    releaseDate() {
      return this.currentRelease.date;
    },
    releaseNoteIntro() {
      let text = this.$t('release-notes-title');
      text = text.replace('{boldStart}', '<span class="has-text-weight-bold">');
      text = text.replace('{boldEnd}', '</span>');
      text = text.replace('{version}', this.version);
      return text;
    },
  },
  mounted() {
    this.parseReleases();
  },
  methods: {
    parseReleases() {
      for (let i = 0; i < releaseNoteData.length; i++) {
        if (releaseNoteData[i].version === this.version) {
          this.currentRelease = releaseNoteData[i];
        } else {
          this.oldReleases.push(releaseNoteData[i]);
        }
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/sass/portal.scss';

li {
  list-style-type: decimal !important;
}
li {
  color: $primary;
}

.mt-05 {
  margin-top: 0.5em;
}
</style>
